import React, { useState } from "react";
import api from "../services/api";
import User from "./User";
import LoaderButton from "../components/LoaderButton";
export default function Saldo() {
  const [comanda, setComanda] = useState(0);
  const [comandaU, setComandaU] = useState(false);
  const [credit, setCredit] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  async function loadData() {
    if (comanda > 2) {
      try {
        setIsLoading(true);
        const res = await api.post("credit/client/67726af733bbd2c1de6f9629", {
          tag: parseInt(comanda, 10),
        });
        setCredit(res.data);
        setComandaU(comanda);
        setIsLoading(false);
      } catch (e) {
        alert("Dados incorretos");
        setComandaU(0);
        setIsLoading(false);
      }
    } else {
      alert("Comanda inválida");
    }
    setIsLoading(false);
  }
  return (
    <div>
      <h1 className="my-4">Verifique seu Saldo</h1>
      <div className="container">
        <form>
          <div className="form-group row my-2">
            <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
              Comanda
            </label>
            <div className="col-sm-10">
              <input
                onChange={(e) => {
                  setComanda(e.target.value);
                }}
                type="number"
                className="form-control form-control-lg"
                id="comanda"
              />
            </div>
          </div>
        </form>
        <LoaderButton
          isLoading={isLoading}
          onClick={loadData}
          className="btn btn-primary"
        >
          Consultar
        </LoaderButton>
      </div>

      <User credit={credit} comanda={comandaU} />
    </div>
  );
}
